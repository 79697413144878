import { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { FormStates, StateValue } from "./FormStateMachine";
import { CorporateDateDetails } from "./corporatedetails/CorporateDateDetails";
import { useCorpStore } from "@/reducers/corporateReducer";
import { CorporateInfo } from "./corporatedetails/CorporateInfo";
import { CorporateBusinessAddress } from "./corporatedetails/CorporateBusinessAddress";
import DirectorInfo from "./directordetails/DirectorInfo";
import DirectorPepInfo from "./directordetails/DirectorPepInfo";
import CorporateIssuerDocuments from "./corporatedetails/CorporateIssuerUpload";
import CorporateArticleOfIncorporationUpload from "./corporatedetails/CorporateDocuments";
import RegistrationComplete from "./RegistrationCompletePage";
import CorporateAdditionalDetails from "./corporatedetails/CorporateAdditionalDetails";
import DirectorPersonalShare from "./directordetails/DirectorPersonalShare";
import AuthorizedDirector from "./directordetails/AuthorizedDirector";
import { DarkModeMenu } from "../signup/DarkmodeSignUpPanel";

export const ROUTES = {
  CORPORATE_INFO: "/corporate-info",
  CORPORATE_BUSINESS_ADDRESS: "/corporate-business-address",
  CORPORATE_BUSINESS_DETAILS: "/corporate-business-details",
  CORPORATE_ADDITIONAL_DETAILS: "/corporate-additional-details",
  CORPORATE_DATE_DETAILS: "/corporate",
  DIRECTOR_INFO: "/director-info",
  DIRECTOR_PEP_INFO: "/director-pep-info",
  CORPORATE_DOCUMENTS: "/corporate-documents",
  COMPLETE: "/registration-complete",
  DIRECTOR_AUTH_INFO: "/director-auth-info",
  CORPORATE_ASSIST: "/corporate-assist",
  CORPORATE_ARTICLE_INCORPORATION: "/corporate-article-incorporation",
  DIRECTOR_PERSONAL_SHARE: "/director-personal-share",
} as const;

const STATE_TO_ROUTE: Record<keyof typeof FormStates, string> = {
  [FormStates.CORPINFO]: ROUTES.CORPORATE_INFO,
  [FormStates.CORP_BUS_ADDRESS]: ROUTES.CORPORATE_BUSINESS_ADDRESS,
  [FormStates.CORP_ADDN_DETAILS]: ROUTES.CORPORATE_ADDITIONAL_DETAILS,
  [FormStates.CORP_DATE_DETAILS]: ROUTES.CORPORATE_DATE_DETAILS,
  [FormStates.DIRECTOR_INFO]: ROUTES.DIRECTOR_INFO,
  [FormStates.DIRECTOR_PEP_INFO]: ROUTES.DIRECTOR_PEP_INFO,
  [FormStates.CORPDOCS]: ROUTES.CORPORATE_DOCUMENTS,
  [FormStates.DIRECTOR_AUTH_INFO]: ROUTES.DIRECTOR_AUTH_INFO,
  [FormStates.CORPORATE_ARTICLE_INCORPORATION]:
    ROUTES.CORPORATE_ARTICLE_INCORPORATION,
  [FormStates.COMPLETE]: ROUTES.COMPLETE,
  [FormStates.DIRECTOR_PERSONAL_SHARE]: ROUTES.DIRECTOR_PERSONAL_SHARE,
};

const ROUTE_TO_STATE: Record<string, keyof typeof FormStates> = {
  [ROUTES.CORPORATE_INFO]: FormStates.CORPINFO,
  [ROUTES.CORPORATE_BUSINESS_ADDRESS]: FormStates.CORP_BUS_ADDRESS,
  [ROUTES.CORPORATE_ADDITIONAL_DETAILS]: FormStates.CORP_ADDN_DETAILS,
  [ROUTES.CORPORATE_DATE_DETAILS]: FormStates.CORP_DATE_DETAILS,
  [ROUTES.DIRECTOR_INFO]: FormStates.DIRECTOR_INFO,
  [ROUTES.DIRECTOR_PEP_INFO]: FormStates.DIRECTOR_PEP_INFO,
  [ROUTES.DIRECTOR_AUTH_INFO]: FormStates.DIRECTOR_AUTH_INFO,
  [ROUTES.CORPORATE_DOCUMENTS]: FormStates.CORPDOCS,
  [ROUTES.DIRECTOR_PERSONAL_SHARE]: FormStates.DIRECTOR_PERSONAL_SHARE,
  [ROUTES.CORPORATE_ARTICLE_INCORPORATION]:
    FormStates.CORPORATE_ARTICLE_INCORPORATION,
  [ROUTES.COMPLETE]: FormStates.COMPLETE,
};
const getFormPanel = (state: StateValue) => {
  switch (state) {
    case FormStates.CORPINFO:
      return <CorporateInfo />;
    case FormStates.DIRECTOR_PERSONAL_SHARE:
      return <DirectorPersonalShare />;
    case FormStates.CORP_BUS_ADDRESS:
      return <CorporateBusinessAddress />;
    case FormStates.DIRECTOR_AUTH_INFO:
      return <AuthorizedDirector />;
    case FormStates.CORP_ADDN_DETAILS:
      return <CorporateAdditionalDetails />;
    case FormStates.CORP_DATE_DETAILS:
      return <CorporateDateDetails />;
    case FormStates.CORPDOCS:
      return <CorporateIssuerDocuments />;
    case FormStates.CORPORATE_ARTICLE_INCORPORATION:
      return <CorporateArticleOfIncorporationUpload />;
    case FormStates.DIRECTOR_INFO:
      return <DirectorInfo />;
    case FormStates.DIRECTOR_PEP_INFO:
      return <DirectorPepInfo />;
    case FormStates.COMPLETE:
      return <RegistrationComplete />;
    default:
      return <Navigate to={STATE_TO_ROUTE[FormStates.CORPINFO]} replace />;
  }
};

export const CorporateSignupPanel = () => {
  const location = useLocation();
  const formState = useCorpStore((state) => state.formState);
  const jumpTo = useCorpStore((state) => state.jumpTo);

  // Sync route with form state
  useEffect(() => {
    const currentState = ROUTE_TO_STATE[location.pathname];
    if (currentState && currentState !== formState) {
      jumpTo(currentState);
    }
  }, [location.pathname, formState, jumpTo]);

  // Get the current panel based on form state
  const currentPanel = getFormPanel(formState);

  return (
    <div className="relative p-5 md:pt-20 md:pb-0 w-full max-w-3xl mx-auto">
      {window.self === window.top && (
        <div className="absolute top-4 right-0 md:top-10 md:right-0">
          <DarkModeMenu />
        </div>
      )}
      <div className="flex-grow">{currentPanel}</div>
    </div>
  );
};
