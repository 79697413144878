import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";

export default function CorporateAssist() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/EasyLogin");
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="w-full">
          <div className="flex justify-center">
            {/* Steps indicator */}
            <div className="steps-container">
              {/* Your existing Steps component would go here */}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 flex flex-col items-center gap-6">
        <p className="text-center text-base">
          Please allow up to 24 hours for a PayTrie support staff to verify your
          identity. If we require more information, we will contact you via
          email.
        </p>

        <Button
          onClick={handleSubmit}
          className="mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
        >
          Return to Homepage
        </Button>
      </div>
    </div>
  );
}
