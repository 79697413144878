import { useState, useEffect, FormEvent } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";

import {
  ErrorBlockText,
  RowAlign,
  TextInput,
} from "@/components/widget-components";
import {
  testCanadianPostalCode,
  testIsValidName,
  getUserAge,
  updateUserCurrentFlow,
} from "@/requests/signup";
import { Button } from "@/components/Button/Button";
import { BirthdayInput } from "@/components/Select/BirthdayInput";
import { ProvinceSelector } from "@/components/Select/ProvinceSelector";
import { useSignupStore } from "@/reducers";
import { FormStates } from "./FormStateMachine";

export default function SignupPersonalInformation() {
  const navigate = useNavigate();
  const {
    email,
    firstName,
    lastName,
    birthDay,
    nextState,
    setAddress1,
    setAddress2,
    setCity,
    setProvince,
    setPostalCode,
    setFirstName,
    setLastName,
    setBirthDay,
    setEmail,
  } = useSignupStore();

  const { streetAddress1, streetAddress2, postalCode, city, province } =
    useSignupStore.use.address();

  const [errorMessage, setErrorMessage] = useState("");

  const searchOptions = {
    componentRestrictions: { country: "ca" },
    types: ["address"],
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`,
    libraries: ["places"],
  });

  const handleChange = (address: string) => {
    setAddress1(address);
  };

  const handleSelect = (address: string) => {
    const addrObj: any = {};
    geocodeByAddress(address)
      .then((results) => {
        const addr = results[0].address_components;
        for (let i = 0; i < addr.length; i++) {
          for (let j = 0; j < addr[i].types.length; j++) {
            addrObj[addr[i].types[j]] = addr[i].long_name;
            if (addr[i].types[j] == "administrative_area_level_1") {
              addrObj[addr[i].types[j]] = addr[i].short_name;
            }
          }
        }
        setAddress1(
          `${addrObj.street_number ? addrObj.street_number : ""} ${addrObj.route ? addrObj.route : ""}`
        );
        setAddress2(`${addrObj.subpremise ? addrObj.subpremise : ""}`);
        setCity(addrObj.locality ? addrObj.locality : "");
        setProvince(
          `${addrObj.administrative_area_level_1 ? addrObj.administrative_area_level_1 : ""}`
        );
        setPostalCode(
          addrObj.postal_code ? addrObj.postal_code.replace(/\s/g, "") : ""
        );
      })
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  const saveFormToLocalStorage = () => {
    const [birthYear, birthMonth, birthDate] = birthDay.split("-");
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("address1", streetAddress1);
    localStorage.setItem("address2", streetAddress2);
    localStorage.setItem("city", city);
    localStorage.setItem("province", province || "");
    localStorage.setItem("postal", postalCode);
    localStorage.setItem("dob1", birthYear);
    localStorage.setItem("dob2", birthMonth);
    localStorage.setItem("dob3", birthDate);
  };

  const validateInformation = (e: FormEvent) => {
    e.preventDefault();
    if (!firstName) {
      setErrorMessage("Please enter your first name");
      return;
    }

    if (!testIsValidName(firstName.trim()) || firstName.length < 2) {
      setErrorMessage("Please enter your first name");
      return;
    }

    if (!lastName) {
      setErrorMessage("Please enter your last name");
      return;
    }

    if (!testIsValidName(lastName.trim()) || lastName.length < 2) {
      setErrorMessage("Please enter your last name");
      return;
    }

    if (!birthDay) {
      setErrorMessage("Please enter birthday");
      return;
    }

    if (!birthDay.match(/^\d{4}-\d{2}-\d{2}$/)) {
      setErrorMessage("Please enter a complete birthday in YYYY-MM-DD format");
      return;
    }

    const [birthYear, birthMonth, birthDate] = birthDay.split("-");
    const parsedBirthdate = [birthYear, birthMonth, birthDate].join("-");

    if (
      getUserAge(Number(birthYear), Number(birthMonth), Number(birthDate)) < 18
    ) {
      setErrorMessage("You must be 18+ to sign up");
      return;
    }

    if (
      Number(birthYear) < 1890 ||
      Number(birthYear) > Number(dayjs().subtract(17, "years").get("year"))
    ) {
      setErrorMessage("Please enter a valid year");
      return;
    }

    if (Number(birthMonth) < 1 || Number(birthMonth) > 12) {
      setErrorMessage("Please enter a valid month");
      return;
    }

    const maxDaysInMonth = new Date(
      Number(birthYear),
      Number(birthMonth),
      0
    ).getDate();

    if (Number(birthDate) < 1 || Number(birthDate) > maxDaysInMonth) {
      setErrorMessage("Please enter a valid day of the month");
      return;
    }

    if (
      !dayjs(parsedBirthdate).isValid() ||
      dayjs(parsedBirthdate).isAfter(dayjs().startOf("d"))
    ) {
      setErrorMessage("Must be a valid date");
      return;
    }

    if (!streetAddress1) {
      setErrorMessage("Please enter your address");
      return;
    }
    if (!testCanadianPostalCode(postalCode ?? "")) {
      setErrorMessage("Postal code must follow the format A1A 1A1");
      return;
    }
    if (!city) {
      setErrorMessage("Please enter your city");
      return;
    }
    if (!province) {
      setErrorMessage("Please select your province");
      return;
    }

    // Save form values to localStorage
    saveFormToLocalStorage();

    nextState();
    // set flow
    updateUserCurrentFlow(email, FormStates.PERSONAL_INFO);
    navigate("/SignupOccupation");
  };

  // useEffect(() => {
  //   const googleMapScript = loadMapApi();
  //   googleMapScript.addEventListener('load', function () {
  //     setScriptLoaded(true);
  //   });
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email") || "");
    }
    if (localStorage.getItem("firstName")) {
      setFirstName(localStorage.getItem("firstName") || "");
    }
    if (localStorage.getItem("lastName")) {
      setLastName(localStorage.getItem("lastName") || "");
    }
    if (localStorage.getItem("address1")) {
      setAddress1(localStorage.getItem("address1") || "");
    }
    if (localStorage.getItem("address2")) {
      setAddress2(localStorage.getItem("address2") || "");
    }
    if (localStorage.getItem("city")) {
      setCity(localStorage.getItem("city") || "");
    }
    if (localStorage.getItem("postal")) {
      setPostalCode(localStorage.getItem("postal") || "");
    }
    if (localStorage.getItem("province")) {
      setProvince(localStorage.getItem("province") || "");
    }
    if (
      localStorage.getItem("dob1") &&
      localStorage.getItem("dob2") &&
      localStorage.getItem("dob3")
    ) {
      setBirthDay(
        localStorage.getItem("dob1") +
          "-" +
          localStorage.getItem("dob2") +
          "-" +
          localStorage.getItem("dob3")
      );
    }
  }, []);

  return (
    <form className="flex flex-col gap-5" onSubmit={validateInformation}>
      <div className="text-lg font-bold uppercase">Personal Information</div>

      <div className="grid grid-cols-2 gap-2 mb-4">
        <TextInput
          placeholder="First name"
          value={firstName}
          maxLength={50}
          autoFocus={true}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextInput
          placeholder="Last name"
          value={lastName}
          maxLength={50}
          onChange={(e) => setLastName(e.target.value)}
        />
        <div className="col-span-2">
          <RowAlign>
            <BirthdayInput value={birthDay} onChange={setBirthDay} />
          </RowAlign>
        </div>

        {isLoaded && (
          <div className="col-span-2">
            <PlacesAutocomplete
              value={streetAddress1}
              onChange={handleChange}
              onSelect={handleSelect}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => {
                return (
                  <div>
                    <div>
                      <TextInput
                        {...getInputProps({
                          placeholder: "Street address",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        <div className="bg-white dark:bg-pebble border-x border-pebble dark:border-white">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "bg-rock p-2 cursor-pointer z-[1001] last:border-b"
                              : "hover:bg-rock p-2 cursor-pointer z-[1001] last:border-b";

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </PlacesAutocomplete>
          </div>
        )}

        <div id="addressForm" className={`col-span-2`}>
          <RowAlign>
            <TextInput
              placeholder="Unit #"
              value={streetAddress2}
              onChange={(e) => setAddress2(e.target.value)}
            />
            <TextInput
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </RowAlign>
          <RowAlign className="mt-2">
            <ProvinceSelector
              province={province}
              onSelectProvince={(val) => setProvince(val)}
            />
            <TextInput
              placeholder="Postal code"
              maxLength={6}
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </RowAlign>
        </div>
      </div>

      <ErrorBlockText>{errorMessage}</ErrorBlockText>
      <Button
        className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
        type="submit"
      >
        Continue
      </Button>
    </form>
  );
}
