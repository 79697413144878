import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { TextInput } from "@/components/widget-components";
import { Checkbox } from "@/components/Checkbox";
import { BirthdayInput } from "@/components/Select/BirthdayInput";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import axiosInstance from "@/requests/axios";
import { TrashIcon } from "@radix-ui/react-icons";
import * as RadioGroup from "@radix-ui/react-radio-group";

interface SavedDirector {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  occupation: string;
  email: string;
}

interface Owner {
  name: string;
  dateOfBirth: string;
  occupation: string;
  email: string;
  isFromDirector?: boolean;
  directorName?: string;
}

interface ValidationError {
  directorEmail?: string;
  ownershipStatus?: string;
  owners?: {
    [key: number]: {
      name?: string;
      dateOfBirth?: string;
      occupation?: string;
      email?: string;
    };
  };
  submit?: string;
}

const normalizeDateString = (dateString: string): string => {
  // Split the input into components
  const parts = dateString.split("-");

  // Pad month and day with leading zeros if needed
  return parts
    .map((part, index) => {
      if (index === 1 || index === 2) {
        // Month and day
        return part.padStart(2, "0");
      }
      return part; // Year
    })
    .join("-");
};

export default function DirectorPersonalShare() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const ownershipStatus = useCorpStore((state) => state.ownershipStatus);
  const setOwnershipStatus = useCorpStore((state) => state.setOwnershipStatus);

  const [owners, setOwners] = useState<Owner[]>([]);

  const [errors, setErrors] = useState<ValidationError>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [savedDirectors, setSavedDirectors] = useState<SavedDirector[]>([]);
  const [selectedDirectors, setSelectedDirectors] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const savedState = localStorage.getItem("directorOwnersState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      if (parsed.ownershipStatus !== undefined)
        setOwnershipStatus(parsed.ownershipStatus);
      if (parsed.owners) setOwners(parsed.owners);
    }
  }, []);

  const isValidDate = (dateString: string) => {
    dateString = normalizeDateString(dateString);

    if (!dateString) return false;

    const dateComponents = dateString.split("-");
    if (
      dateComponents.length !== 3 ||
      dateComponents[0].length !== 4 || // Year
      dateComponents[1].length !== 2 || // Month
      dateComponents[2].length !== 2
    ) {
      console.log("eeeee");
      // Day
      return false;
    }

    const birthDate = new Date(dateString);
    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 120);

    // Calculate age precisely
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    const isNotYetBirthday = monthDiff < 0 || (monthDiff === 0 && dayDiff < 0);
    const finalAge = isNotYetBirthday ? age - 1 : age;
    console.log(
      birthDate instanceof Date &&
        !isNaN(birthDate.getTime()) &&
        birthDate <= today &&
        birthDate >= minDate &&
        finalAge >= 18
    );
    return (
      birthDate instanceof Date &&
      !isNaN(birthDate.getTime()) &&
      birthDate <= today &&
      birthDate >= minDate &&
      finalAge >= 18
    );
  };

  useEffect(() => {
    // Load previously saved directors
    const directorInfoState = localStorage.getItem("directorInfoState");
    if (directorInfoState) {
      const { directors } = JSON.parse(directorInfoState);
      setSavedDirectors(directors || []);
    }

    // Load current state if it exists
    const savedState = localStorage.getItem("directorOwnersState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      if (parsed.ownershipStatus !== undefined)
        setOwnershipStatus(parsed.ownershipStatus);
      if (parsed.owners) {
        setOwners(parsed.owners);
        // Reconstruct selected directors state
        const selected = parsed.owners.reduce(
          (acc: { [key: string]: boolean }, owner: Owner) => {
            if (owner.isFromDirector && owner.directorName) {
              acc[owner.directorName] = true;
            }
            return acc;
          },
          {}
        );
        setSelectedDirectors(selected);
      }
    }
  }, []);

  const handleOwnershipChange = (value: boolean) => {
    const newOwnershipStatus = value;
    let newOwners = [...owners]; // Copy current owners

    if (!value) {
      newOwners = [];
      setOwners(newOwners); // Update state
    }

    setOwnershipStatus(newOwnershipStatus);
    setTouched((prev) => ({ ...prev, ownershipStatus: true }));

    // Validate with updated values immediately
    const formErrors = validateForm(newOwnershipStatus, newOwners);
    setErrors(formErrors);
  };

  const handleOwnerChange = (
    index: number,
    field: keyof Owner,
    value: string
  ) => {
    const newOwners = [...owners];

    const directorOwners = newOwners.filter((owner) => owner.isFromDirector);
    const actualIndex = directorOwners.length + index;

    // Update the copy directly
    newOwners[actualIndex] = {
      ...newOwners[actualIndex],
      [field]: value,
    };

    // Update state with modified copy
    setOwners(newOwners);

    setTouched((prev) => ({ ...prev, [`${actualIndex}-${field}`]: true }));

    // Validate using the already-updated newOwners copy
    const formErrors = validateForm(ownershipStatus, newOwners);
    setErrors(formErrors);
  };

  const validateForm = (
    ownershipStatusToValidate: boolean | null | undefined,
    ownersToValidate: Owner[]
  ): ValidationError => {
    const newErrors: ValidationError = {};

    if (
      ownershipStatusToValidate === null ||
      ownershipStatusToValidate === undefined
    ) {
      newErrors.ownershipStatus = "Please indicate ownership status";
    }

    if (ownershipStatusToValidate === true) {
      const ownerErrors: ValidationError["owners"] = {};

      ownersToValidate.forEach((owner, index) => {
        const currentErrors: any = {};

        if (!owner.name?.trim()) {
          currentErrors.name = "Name is required";
        }

        if (!owner.email?.trim()) {
          currentErrors.email = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(owner.email)) {
          currentErrors.email = "Please enter a valid email address";
        }

        if (!owner.occupation?.trim()) {
          currentErrors.occupation = "Occupation is required";
        }

        if (!owner.dateOfBirth) {
          currentErrors.dateOfBirth = "Date of birth is required";
        } else if (!isValidDate(owner.dateOfBirth)) {
          const dateComponents = owner.dateOfBirth.split("-");
          if (
            dateComponents.length !== 3 ||
            dateComponents[0].length !== 4 ||
            dateComponents[1].length !== 2 ||
            dateComponents[2].length !== 2
          ) {
            currentErrors.dateOfBirth =
              "Please enter a complete date (YYYY-MM-DD)";
          } else {
            currentErrors.dateOfBirth = "Owner must be at least 18 years old";
          }
        }

        if (Object.keys(currentErrors).length > 0) {
          ownerErrors[index] = currentErrors;
        }
      });

      if (Object.keys(ownerErrors).length > 0) {
        newErrors.owners = ownerErrors;
      }
    }

    return newErrors;
  };

  const addOwner = () => {
    const totalOwners = owners.length;
    if (totalOwners < 4) {
      setOwners([
        ...owners,
        {
          name: "",
          dateOfBirth: "",
          occupation: "",
          email: "",
        },
      ]);
    }
  };
  const removeOwner = (index: number) => {
    // Get only manual owners
    const manualOwners = owners.filter((owner) => !owner.isFromDirector);
    if (manualOwners.length > 0) {
      // Find the actual index in the full owners array
      const directorOwners = owners.filter((owner) => owner.isFromDirector);
      const actualIndex = directorOwners.length + index;

      const newOwners = [...owners];
      newOwners.splice(actualIndex, 1);
      setOwners(newOwners);

      // Clear errors and touched state for removed owner
      const newErrors = { ...errors };
      if (newErrors.owners) {
        delete newErrors.owners[actualIndex];
        if (Object.keys(newErrors.owners).length === 0) {
          delete newErrors.owners;
        }
      }
      setErrors(newErrors);

      const newTouched = { ...touched };
      Object.keys(newTouched).forEach((key) => {
        if (key.startsWith(`${actualIndex}-`)) {
          delete newTouched[key];
        }
      });
      setTouched(newTouched);
    }
  };

  const handleSubmit = async () => {
    // Mark all fields as touched first
    const allTouched: { [key: string]: boolean } = {
      ownershipStatus: true,
    };
    if (ownershipStatus) {
      owners.forEach((_, index) => {
        allTouched[`${index}-name`] = true;
        allTouched[`${index}-dateOfBirth`] = true;
        allTouched[`${index}-occupation`] = true;
      });
    }
    setTouched(allTouched);

    // Validate all fields
    const formErrors = validateForm(ownershipStatus, owners);
    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0 || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Save to localStorage
      localStorage.setItem(
        "directorOwnersState",
        JSON.stringify({
          ownershipStatus,
          owners: ownershipStatus ? owners : [],
        })
      );

      // Get corporation_email from corporateInfoState
      const corporateInfoState = localStorage.getItem("corporateInfoState");
      const corporateInfo = corporateInfoState
        ? JSON.parse(corporateInfoState)
        : {};
      const corporateEmail = corporateInfo.corporateEmail?.toLowerCase();

      const payload = {
        corporation_email: corporateEmail,
        is_quarter_owner: ownershipStatus,
        list_of_quarter_owners: {
          quarterOwners: ownershipStatus
            ? owners.map((owner) => owner.name.toLowerCase())
            : [],
          quarterOwnersDOB: ownershipStatus
            ? owners.map(
                (owner) =>
                  new Date(owner.dateOfBirth).toISOString().split("T")[0]
              )
            : [],
          quarterOwnersOccupation: ownershipStatus
            ? owners.map((owner) => owner.occupation.toLowerCase())
            : [],
          quarterOwnersEmail: ownershipStatus
            ? owners.map((owner) => owner.email.toLowerCase())
            : [],
        },
      };

      await axiosInstance({
        method: "post",
        url: "/updateCorporate",
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      });

      nextState();
      navigate(ROUTES.DIRECTOR_AUTH_INFO);
    } catch (error) {
      console.error("Error updating owners information:", error);
      setErrors((prev) => ({
        ...prev,
        submit:
          "Unfortunately we were unable to process your information at this time. Please try again later or contact us at support@paytrie.com",
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDirectorSelect = (director: SavedDirector, checked: boolean) => {
    const directorFullName = `${director.firstName} ${director.lastName}`;

    setSelectedDirectors((prev) => ({
      ...prev,
      [directorFullName]: checked,
    }));

    if (checked) {
      // Add director to owners list
      const newOwner: Owner = {
        name: directorFullName,
        dateOfBirth: director.dateOfBirth,
        occupation: director.occupation,
        isFromDirector: true,
        email: director.email,
        directorName: directorFullName,
      };
      setOwners((prev) => [...prev, newOwner]);
    } else {
      // Remove director from owners list
      setOwners((prev) =>
        prev.filter((owner) => owner.directorName !== directorFullName)
      );
    }
  };

  const areOwnerFieldsFilled = (owner: Owner): boolean => {
    return (
      owner.name.trim() !== "" &&
      owner.dateOfBirth !== "" &&
      owner.occupation.trim() !== "" &&
      owner.email.trim() !== "" &&
      isValidDate(owner.dateOfBirth)
    );
  };

  const handleInputKeyDown = async (e: React.KeyboardEvent) => {
    const isFormComplete =
      !isSubmitting &&
      ownershipStatus !== null &&
      ownershipStatus !== undefined &&
      (!ownershipStatus ||
        (ownershipStatus === true &&
          owners.length > 0 &&
          owners.every((owner) => areOwnerFieldsFilled(owner))));

    if (e.key === "Enter" && isFormComplete) {
      e.preventDefault();
      await handleSubmit();
    }
  };

  useEffect(() => {
    const handleDocumentKeyDown = async (e: KeyboardEvent) => {
      const isFormComplete =
        !isSubmitting &&
        ownershipStatus !== null &&
        ownershipStatus !== undefined &&
        (!ownershipStatus ||
          (ownershipStatus === true &&
            owners.length > 0 &&
            owners.every((owner) => areOwnerFieldsFilled(owner))));

      if (e.key === "Enter" && isFormComplete) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    document.addEventListener("keydown", handleDocumentKeyDown);
    return () => {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    };
  }, [isSubmitting, ownershipStatus, owners]);

  console.log(
    isSubmitting,
    ownershipStatus === null,
    ownershipStatus === undefined,
    ownershipStatus === true &&
      (owners.length === 0 ||
        !owners.every((owner) => areOwnerFieldsFilled(owner)))
  );

  return (
    <div className="flex flex-col h-[600px]" tabIndex={-1}>
      {/* Fixed Header Section */}
      <div className="flex-none space-y-6 mb-4">
        <div className="text-lg font-bold uppercase">OWNER'S INFORMATION</div>

        <div className="flex flex-col gap-2">
          <p className="text-base">
            Does any individual directly or indirectly own 25% or more of the
            company?
          </p>
          <RadioGroup.Root
            value={ownershipStatus === null ? "" : ownershipStatus.toString()}
            onValueChange={(value) => handleOwnershipChange(value === "true")}
            className="flex items-center gap-8"
          >
            <div className="flex items-center space-x-3">
              <RadioGroup.Item
                value="true"
                id="ownership-yes"
                className="w-4 h-4 rounded-full border border-gray-300 dark:border-gray-600"
              >
                <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-2 after:h-2 after:rounded-full after:bg-black dark:after:bg-white" />
              </RadioGroup.Item>
              <label
                htmlFor="ownership-yes"
                className="text-md text-gray-900 dark:text-gray-100"
              >
                Yes
              </label>
            </div>

            <div className="flex items-center space-x-3">
              <RadioGroup.Item
                value="false"
                id="ownership-no"
                className="w-4 h-4 rounded-full border border-gray-300 dark:border-gray-600"
              >
                <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-2 after:h-2 after:rounded-full after:bg-black dark:after:bg-white" />
              </RadioGroup.Item>
              <label
                htmlFor="ownership-no"
                className="text-md text-gray-900 dark:text-gray-100"
              >
                No
              </label>
            </div>
          </RadioGroup.Root>
          {touched.ownershipStatus && errors.ownershipStatus && (
            <span className="text-sm text-red-500 mt-1">
              {errors.ownershipStatus}
            </span>
          )}
        </div>
      </div>

      {/* Scrollable Owners Section */}
      <div className="flex-1 min-h-0 overflow-y-auto">
        {/* Add Directors Checkbox Section */}

        {ownershipStatus && (
          <div className="space-y-4 pr-2">
            {savedDirectors.length > 0 && (
              <div className="mb-6 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <h3 className="font-semibold mb-4">Owners</h3>
                <div className="space-y-3">
                  {savedDirectors.map((director, idx) => (
                    <div key={idx} className="flex items-center gap-2">
                      <Checkbox
                        checked={
                          selectedDirectors[
                            `${director.firstName} ${director.lastName}`
                          ] || false
                        }
                        onCheckedChange={(checked) =>
                          handleDirectorSelect(director, checked as boolean)
                        }
                        label={`${director.firstName} ${director.lastName}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {owners
              .filter((owner) => owner.isFromDirector)
              .map((owner, index) => (
                <div
                  key={`director-${index}`}
                  className="flex flex-col gap-6 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
                >
                  <div className="flex justify-between items-center">
                    <h3 className="font-semibold">Owner {index + 1}</h3>
                  </div>

                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="Full Name"
                      value={owner.name}
                      onChange={(e) =>
                        handleOwnerChange(index, "name", e.target.value)
                      }
                      onKeyDown={handleInputKeyDown}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <BirthdayInput
                      value={owner.dateOfBirth}
                      onChange={() => {}} // Empty handler since it's disabled
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="Occupation"
                      value={owner.occupation}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <TextInput
                      placeholder="Email"
                      value={owner.email}
                      type="email"
                      disabled={true}
                    />
                  </div>
                </div>
              ))}

            {/* Then show manually added owners */}
            {owners
              .filter((owner) => !owner.isFromDirector)
              .map((owner, index) => {
                const totalCheckedDirectors = owners.filter(
                  (o) => o.isFromDirector
                ).length;
                const displayIndex = index + totalCheckedDirectors + 1;

                return (
                  <div
                    key={`manual-${index}`}
                    className="flex flex-col gap-6 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
                  >
                    <div className="flex justify-between items-center">
                      <h3 className="font-semibold">Owner {displayIndex}</h3>

                      <button
                        onClick={() => removeOwner(index)}
                        className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50 transition-colors"
                      >
                        <TrashIcon />
                      </button>
                    </div>

                    <div className="flex flex-col gap-1">
                      <TextInput
                        placeholder="Full Name"
                        value={owner.name}
                        onChange={(e) =>
                          handleOwnerChange(index, "name", e.target.value)
                        }
                      />
                      {touched[`${index + totalCheckedDirectors}-name`] &&
                        errors.owners?.[index + totalCheckedDirectors]
                          ?.name && (
                          <span className="text-sm text-red-500 mt-1">
                            {errors.owners[index + totalCheckedDirectors].name}
                          </span>
                        )}
                    </div>

                    <div className="flex flex-col gap-2">
                      <BirthdayInput
                        value={owner.dateOfBirth}
                        onChange={(value) =>
                          handleOwnerChange(index, "dateOfBirth", value)
                        }
                      />
                      {touched[
                        `${index + totalCheckedDirectors}-dateOfBirth`
                      ] &&
                        errors.owners?.[index + totalCheckedDirectors]
                          ?.dateOfBirth && (
                          <span className="text-sm text-red-500 mt-1">
                            {
                              errors.owners[index + totalCheckedDirectors]
                                .dateOfBirth
                            }
                          </span>
                        )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <TextInput
                        placeholder="Occupation"
                        value={owner.occupation}
                        onChange={(e) =>
                          handleOwnerChange(index, "occupation", e.target.value)
                        }
                      />
                      {touched[`${index + totalCheckedDirectors}-occupation`] &&
                        errors.owners?.[index + totalCheckedDirectors]
                          ?.occupation && (
                          <span className="text-sm text-red-500 mt-1">
                            {
                              errors.owners[index + totalCheckedDirectors]
                                .occupation
                            }
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col gap-1">
                      <TextInput
                        placeholder="Email"
                        type="email"
                        value={owner.email}
                        onChange={(e) =>
                          handleOwnerChange(index, "email", e.target.value)
                        }
                      />
                      {touched[`${index + totalCheckedDirectors}-email`] &&
                        errors.owners?.[index + totalCheckedDirectors]
                          ?.email && (
                          <span className="text-sm text-red-500 mt-1">
                            {errors.owners[index + totalCheckedDirectors].email}
                          </span>
                        )}
                    </div>
                  </div>
                );
              })}

            <>
              {owners.filter((owner) => !owner.isFromDirector).length < 4 && (
                <Button
                  onClick={addOwner}
                  className="w-full flex items-center justify-center mb-4"
                >
                  Add Another Owner
                </Button>
              )}
            </>
          </div>
        )}
      </div>

      <div className="flex-none pt-4">
        {errors.submit && (
          <span className="text-sm text-red-500 block mb-4">
            {errors.submit}
          </span>
        )}

        <Button
          onClick={handleSubmit}
          disabled={
            isSubmitting ||
            ownershipStatus === null ||
            ownershipStatus === undefined ||
            (ownershipStatus === true &&
              (owners.length === 0 ||
                !owners.every((owner) => areOwnerFieldsFilled(owner))))
          }
          className="inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
        >
          {isSubmitting ? "Saving..." : "Continue"}
        </Button>
      </div>
    </div>
  );
}
