import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { ProtectedRoute } from "./components/ProtectedRoute";
import { BuySell } from "./pages/Buy";
import { Buy } from "./pages/Buy/Transact/Buy";
import { DashboardHistory } from "./pages/Dashboard/DashboardHistory";
import { DashboardProfile } from "./pages/Dashboard/DashboardProfile";
import { DashboardTrade } from "./pages/Dashboard/DashboardTrade";
import Confirmation from "./pages/Orders/Confirmation";
import OrderStatus from "./pages/Orders/OrderStatus";
import { Signup } from "./pages/Signup";
import Upload from "./pages/Upload/Upload";
import EmailLoginPanel from "./panels/login/EmailLoginPanel";
import KeyLoginPanel from "./panels/login/KeyLoginPanel";
import LoginVerifyPanel from "./panels/login/LoginVerifyPanel";
import { CompleteRegistration } from "./panels/signup/CompleteRegistration";
import { CompleteRegistrationStandard } from "./panels/signup/CompleteRegistrationStandard";
import OliuIframe from "./panels/signup/OliuIFrame";
import { RegistrationFailure } from "./panels/signup/RegistrationFailure";
import OliuRedirectFailed from "./panels/signup/OliuRedirectFailed";
import OliuRedirectSuccess from "./panels/signup/OliuRedirectSuccess";
import DashboardOTC from "./pages/Dashboard/DashboardOTC";
// import PaytrieWidget from "./PaytrieWidget";

import { CorporateSignup } from "./pages/CorporateSignup/CorporateSignup";
import CorporateAssist from "./panels/corporatesignup/corporatedetails/CorporateAssist";


// Corporate routes configuration
const corporateRoutes = [
  {
    path: "/",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate-info",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate-business-address",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate-business-details",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate-additional-details",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate-date-details",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate-documents",
    element: <CorporateSignup />,
  },
  {
    path: "/director-info",
    element: <CorporateSignup />,
  },
  {
    path: "/director-additional-info",
    element: <CorporateSignup />,
  },
  {
    path: "/director-pep-info",
    element: <CorporateSignup />,
  },
  {
    path: "/director-auth-info",
    element: <CorporateSignup />,
  },
  {
    path: "/registration-complete",
    element: <CorporateSignup />,
  },
  {
    path: "/corporate-assist",
    element: <CorporateAssist />,
  },
  {
    path: "/corporate-article-incorporation",
    element: <CorporateSignup />,
  },
  {
    path: "/director-personal-share",
    element: <CorporateSignup />,
  },
  {
    path: "*",
    element: <Navigate to="/corporate" replace />,
  },
];

// Base routes configuration
const baseRoutes = [
  {
    path: "/",
    element: <Signup />,
  },
  {
    path: "/Signup",
    element: <Signup />,
  },
  {
    path: "/SignupEmail",
    element: <Signup />,
  },
  {
    path: "/SignupVerifyEmail",
    element: <Signup />,
  },
  {
    path: "/SignupPhone",
    element: <Signup />,
  },
  {
    path: "/SignupVerifySMS",
    element: <Signup />,
  },
  {
    path: "/SignupDisclosure",
    element: <Signup />,
  },
  {
    path: "/SignupUserInfo",
    element: <Signup />,
  },
  {
    path: "/SignupOccupation",
    element: <Signup />,
  },
  {
    path: "/SignupKYC",
    element: <Signup />,
  },
  {
    path: "/SignupPassword",
    element: <Signup />,
  },
  // {
  //   path: "/EasyLogin",
  //   element: <EmailLoginPanel landing={false} />,
  // },
  // {
  //   path: "/LandingLogin",
  //   element: <EmailLoginPanel landing={true} />,
  // },
  // {
  //   path: "/Login",
  //   element: <Navigate to="/LandingLogin" replace />,
  // },
  // {
  //   path: "/KeyLogin",
  //   element: <KeyLoginPanel />,
  // },
  // {
  //   path: "/LoginVerify",
  //   element: <LoginVerifyPanel />,
  // },
  {
    path: "/OliuIframe",
    element: <OliuIframe />,
  },
  {
    path: "/Complete",
    element: <CompleteRegistration />,
  },
  {
    path: "/Complete24",
    element: <CompleteRegistrationStandard />,
  },
  {
    path: "/Failed",
    element: <RegistrationFailure />,
  },
  {
    path: "/RedirectSuccess",
    element: <OliuRedirectSuccess />,
  },
  {
    path: "/RedirectFailed",
    element: <OliuRedirectFailed />,
  },
  // {
  //   path: "/Status",
  //   element: <OrderStatus />,
  // },
  // {
  //   path: "/Confirmation",
  //   element: <Confirmation />,
  // },
  // {
  //   path: "/Upload",
  //   element: <Upload />,
  // },
  // {
  //   element: <ProtectedRoute />,
  //   children: [
  //     {
  //       path: "/Transactions",
  //       element: <Buy />,
  //     },
  //     {
  //       path: "/Dashboard",
  //       element: <DashboardTrade />,
  //     },
  //     {
  //       path: "/History",
  //       element: <DashboardHistory />,
  //     },
  //     {
  //       path: "/Profile",
  //       element: <DashboardProfile />,
  //     },
  //     {
  //       path: "/OTC",
  //       element: <DashboardOTC />,
  //     },
  //   ],
  // },
  {
    path: "*",
    element: <Signup />,
  },
];

const routes =
  import.meta.env.VITE_ENABLE_CORPORATE === "true"
    ? [...corporateRoutes]
    : baseRoutes;
const router = createBrowserRouter(routes);

const App = () => {
  return (
    <div className="w-full md:flex md:justify-center md:items-center">
      <div className="lg:max-w-[730px] lg:w-[730px]">
        <RouterProvider router={router} />
      </div>
    </div>
  );
};

export default App;
