import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { TextInput, TocSmallText } from "@/components/widget-components";
import { useCorpStore } from "@/reducers/corporateReducer";
import { ROUTES } from "../CorporateSignupPanel";
import axiosInstance from "@/requests/axios";

interface ValidationError {
  corporationName?: string;
  corporateEmail?: string;
  corporationNumber?: string;
  corporatePhone?: string;
}

const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePhone = (phone: string): boolean => {
  const digitsOnly = phone.replace(/\D/g, "");
  return digitsOnly.length === 10;
};

const formatPhoneNumber = (value: string): string => {
  const digitsOnly = value.replace(/\D/g, "");

  if (digitsOnly.length >= 6) {
    return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
  } else if (digitsOnly.length >= 3) {
    return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
  }
  return digitsOnly;
};

export function CorporateInfo() {
  const navigate = useNavigate();
  const nextState = useCorpStore((state) => state.nextState);
  const corporationName = useCorpStore((state) => state.corporationName);
  const setCorporationName = useCorpStore((state) => state.setCorporationName);
  const corporateEmail = useCorpStore((state) => state.corporateEmail);
  const setCorporateEmail = useCorpStore((state) => state.setCorporateEmail);
  const corporationNumber = useCorpStore((state) => state.corporationNumber);
  const setCorporationNumber = useCorpStore(
    (state) => state.setCorporationNumber
  );
  const corporatePhone = useCorpStore((state) => state.corporatePhone);
  const setCorporatePhone = useCorpStore((state) => state.setCorporatePhone);

  const [errors, setErrors] = useState<ValidationError>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  // New state to track if form is valid
  const [isFormValid, setIsFormValid] = useState(false);

  // Load state on component mount
  useEffect(() => {
    const savedState = localStorage.getItem("corporateInfoState");
    if (savedState) {
      const parsed = JSON.parse(savedState);
      setCorporationName(parsed.corporationName || "");
      setCorporateEmail(parsed.corporateEmail || "");
      setCorporationNumber(parsed.corporationNumber || "");
      setCorporatePhone(parsed.corporatePhone || "");
    }
  }, []);

  // Check form validity whenever fields change
  useEffect(() => {
    const formErrors = validateForm();
    const areAllFieldsFilled =
      corporationName.trim() !== "" &&
      corporateEmail.trim() !== "" &&
      corporationNumber.trim() !== "" &&
      corporatePhone.trim() !== "";

    setIsFormValid(Object.keys(formErrors).length === 0 && areAllFieldsFilled);
  }, [corporationName, corporateEmail, corporationNumber, corporatePhone]);

  const validateForm = (): ValidationError => {
    const newErrors: ValidationError = {};

    if (!corporationName.trim()) {
      newErrors.corporationName = "Business name is required";
    }

    if (!corporateEmail.trim()) {
      newErrors.corporateEmail = "Email is required";
    } else if (!validateEmail(corporateEmail)) {
      newErrors.corporateEmail = "Please enter a valid email address";
    }

    if (!corporationNumber.trim()) {
      newErrors.corporationNumber = "Business number is required";
    }

    if (!corporatePhone.trim()) {
      newErrors.corporatePhone = "Phone number is required";
    } else if (!validatePhone(corporatePhone)) {
      newErrors.corporatePhone = "Please enter a valid 10-digit phone number";
    }

    return newErrors;
  };

  const handleBlur = (field: keyof ValidationError) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const formErrors = validateForm();
    if (formErrors[field]) {
      setErrors((prev) => ({ ...prev, [field]: formErrors[field] }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setCorporatePhone(formattedPhone);
  };

  const handleSubmit = async () => {
    const formErrors = validateForm();
    setErrors(formErrors);
    setTouched({
      corporationName: true,
      corporateEmail: true,
      corporationNumber: true,
      corporatePhone: true,
    });

    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      setApiError(null);

      try {
        const formData = {
          corporation_name: corporationName,
          corporation_email: corporateEmail.toLowerCase(),
          corporation_number: corporationNumber,
          corporate_phone: corporatePhone,
          user_email: window.sessionStorage.getItem("email")?.toLowerCase(),
        };

        await axiosInstance.post("/updateCorporate", formData);

        const stateToSave = {
          corporationName,
          corporateEmail,
          corporationNumber,
          corporatePhone,
        };
        localStorage.setItem("corporateInfoState", JSON.stringify(stateToSave));

        nextState();
        navigate(ROUTES.CORPORATE_DATE_DETAILS);
      } catch (error: any) {
        console.error("Error updating corporate info:", error);
        setApiError(
          error.response?.data?.message ||
            "An error occurred while updating corporate information. Please try again."
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && isFormValid && !isSubmitting) {
      e.preventDefault();
      await handleSubmit();
    }
  };

  useEffect(() => {
    // Function to handle keydown events
    const handleGlobalKeyDown = async (e: KeyboardEvent) => {
      if (e.key === "Enter" && isFormValid && !isSubmitting) {
        e.preventDefault();
        await handleSubmit();
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleGlobalKeyDown);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [isFormValid, isSubmitting]); // Dependencies ensure the listener updates when these values change

  return (
    <div className="flex flex-col gap-6" onKeyDown={handleKeyDown} tabIndex={0}>
      {" "}
      <div className="text-lg font-bold uppercase">SIGN UP</div>
      {apiError && (
        <div className="text-red-500 text-sm bg-red-50 p-3 rounded-md">
          {apiError}
        </div>
      )}
      <div className="flex flex-col gap-1">
        <TextInput
          placeholder="Business legal name"
          value={corporationName}
          maxLength={50}
          onChange={(e) => setCorporationName(e.target.value)}
          onBlur={() => handleBlur("corporationName")}
        />
        {touched.corporationName && errors.corporationName && (
          <span className="text-sm text-red-500 mt-1">
            {errors.corporationName}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <TextInput
          placeholder="Business email address"
          value={corporateEmail}
          type="email"
          maxLength={50}
          onChange={(e) => setCorporateEmail(e.target.value)}
          onBlur={() => handleBlur("corporateEmail")}
        />
        <span className="text-xs text-gray-500">
          Please use a different email for your business and personal accounts
        </span>
        {touched.corporateEmail && errors.corporateEmail && (
          <span className="text-sm text-red-500 mt-1">
            {errors.corporateEmail}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <TextInput
          placeholder="Business number"
          value={corporationNumber}
          maxLength={50}
          onChange={(e) => setCorporationNumber(e.target.value)}
          onBlur={() => handleBlur("corporationNumber")}
        />
        {touched.corporationNumber && errors.corporationNumber && (
          <span className="text-sm text-red-500 mt-1">
            {errors.corporationNumber}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <TextInput
          placeholder="Phone number"
          value={corporatePhone}
          type="tel"
          maxLength={15}
          onChange={handlePhoneChange}
          onBlur={() => handleBlur("corporatePhone")}
        />
        {touched.corporatePhone && errors.corporatePhone && (
          <span className="text-sm text-red-500 mt-1">
            {errors.corporatePhone}
          </span>
        )}
      </div>
      <TocSmallText>
        By signing up, you agree to Paytrie's{" "}
        <a
          href={import.meta.env.VITE_PAYTRIE_TOC_URL}
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href={import.meta.env.VITE_PAYTRIE_PRIVACY_URL}
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          Privacy Statement
        </a>
        , and Oliu's{" "}
        <a
          href={import.meta.env.VITE_OLIU_TOC_LINK_URL}
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href={import.meta.env.VITE_OLIU_TOC_PRIVACY_URL}
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          Privacy Statement
        </a>
        . By providing your email, you consent to receiving communications from
        Paytrie. You can opt-out anytime.
      </TocSmallText>
      <Button
        onClick={handleSubmit}
        disabled={isSubmitting || !isFormValid}
        className="mt-4 inline-flex items-center py-8 px-4 w-full justify-center lg:gap-2"
      >
        {isSubmitting ? "Saving..." : "Continue"}
      </Button>
    </div>
  );
}
