import { useEffect, useRef } from "react";

import { PaytrieTopIcon, PoweredByLabel } from "@/components/widget-components";
import { useSignupStore } from "@/reducers";
import { CompleteRegistration } from "./CompleteRegistration";
import { DarkModeMenu } from "./DarkmodeSignUpPanel";
import { FormStates, StateValue } from "./FormStateMachine";
import SignupContentEmail from "./SignupContentEmail";
//import SignupContentKYC from './SignupContentKYC';
//import SignupContentOliuKYC from './SignupContentOliuKYC';
import SignupContentPassword from "./SignupContentPassword";
import SignupContentVerifyEmail from "./SignupContentVerifyEmail";
import SignupContentPhoneNumber from "./SignupContentPhoneNumber";
import SignupContentVerifyPhoneNumber from "./SignupVerifyPhoneNumber";
import SignupDisclosure from "./SignupDisclosure";
import SignupKYCQuestions from "./SignupKYCQuestions";
import SignupOccupation from "./SignupOccupation";
import SignupPersonalInformation from "./SignupPersonalInformation";

const getFormPanel = (state: StateValue) => {
  switch (state) {
    // case FormStates.KYC:
    //   return <SignupContentKYC />;
    case FormStates.EMAIL:
      return <SignupContentEmail />;
    case FormStates.VERIFY_EMAIL:
      return <SignupContentVerifyEmail />;
    case FormStates.PASSWORD:
      return <SignupContentPassword />;
    case FormStates.PHONE:
      return <SignupContentPhoneNumber />;
    case FormStates.VERIFY_PHONE:
      return <SignupContentVerifyPhoneNumber />;
    case FormStates.DISCLOSURE:
      return <SignupDisclosure />;
    case FormStates.PERSONAL_INFO:
      return <SignupPersonalInformation />;
    case FormStates.OCCUPATION:
      return <SignupOccupation />;
    case FormStates.KYC_GENERATE_QUESTIONS:
      return <SignupKYCQuestions />;
    case FormStates.COMPLETE:
      return <CompleteRegistration />;
  }
};

export const SignupPanel = () => {
  const [formState, reset, jumpTo] = [
    useSignupStore.use.formState(),
    useSignupStore.use.reset(),
    useSignupStore.use.jumpTo(),
  ];
  const isFirstMount = useRef(true);
  const appHost = import.meta.env.VITE_APPLICATION_URL;
  const signupOnlyHost = import.meta.env.VITE_SIGNUP_ONLY_APP_URL;

  let displayedPage = getFormPanel(formState);
  // ONLY APPLICABLE FOR FUTURA ENVIRONMENT
  if (appHost === signupOnlyHost && window.location.pathname === '/') {
    displayedPage = getFormPanel(FormStates.EMAIL);
    jumpTo(FormStates.EMAIL);
  }
  if (window.location.pathname === "/SignupEmail") {
    displayedPage = getFormPanel(FormStates.EMAIL);
    jumpTo(FormStates.EMAIL);
  }
  if (window.location.pathname === "/SignupVerifyEmail") {
    displayedPage = getFormPanel(FormStates.VERIFY_EMAIL);
    jumpTo(FormStates.VERIFY_EMAIL);
  }
  if (window.location.pathname === "/SignupPhone") {
    displayedPage = getFormPanel(FormStates.PHONE);
    jumpTo(FormStates.PHONE);
  }
  if (window.location.pathname === "/SignupVerifySMS") {
    displayedPage = getFormPanel(FormStates.VERIFY_PHONE);
    jumpTo(FormStates.VERIFY_PHONE);
  }
  if (window.location.pathname === "/SignupDisclosure") {
    displayedPage = getFormPanel(FormStates.DISCLOSURE);
    jumpTo(FormStates.DISCLOSURE);
  }
  if (window.location.pathname === "/SignupUserInfo") {
    displayedPage = getFormPanel(FormStates.PERSONAL_INFO);
    jumpTo(FormStates.PERSONAL_INFO);
  }
  if (window.location.pathname === "/SignupOccupation") {
    displayedPage = getFormPanel(FormStates.OCCUPATION);
    jumpTo(FormStates.OCCUPATION);
  }
  if (window.location.pathname === "/SignupKYC") {
    displayedPage = getFormPanel(FormStates.KYC_GENERATE_QUESTIONS);
    jumpTo(FormStates.KYC_GENERATE_QUESTIONS);
  }
  if (window.location.pathname === "/SignupPassword") {
    displayedPage = getFormPanel(FormStates.PASSWORD);
    jumpTo(FormStates.PASSWORD);
  }

  useEffect(() => {
    // Clears the signup form data on unmount, i.e navigating away or going back to welcome page
    if (!isFirstMount.current) {
      return reset;
    }
    isFirstMount.current = false;
  }, []);

  return (
    <div className="relative p-5 md:pt-20 md:pb-0 w-full">
      {window.self === window.top && (
        <div className="absolute top-4 right-0 md:top-10 md:right-0">
          <DarkModeMenu />
        </div>
      )}
      <div className="flex-grow">{displayedPage}</div>
      {/* <PoweredByLabel /> */}
    </div>
  );
};
